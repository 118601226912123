import { MyVacanciesFilterInput, MyVacanciesSortType } from '@alliance/shared/domain-gql'
import { RxStateService } from '@alliance/shared/models'
import { getNonNullableItems } from '@alliance/shared/utils'
import { Inject, Injectable, InjectionToken, Optional } from '@angular/core'
import { catchError, combineLatest, finalize, Observable, of } from 'rxjs'
import { filter, map, switchMap, take } from 'rxjs/operators'
import { VacanciesEmployerIdsService } from '../vacancies-employer-ids.service'
import { GetSimpleVacanciesListGQL, SimpleVacanciesListItemFragment, SimpleVacanciesListResponseFragment } from './simple-vacancies-list.generated'

const DEFAULT_ITEMS_PER_PAGE = 10

export interface SimpleVacanciesListConfig {
  itemsPerPage?: number
}

export interface SimpleVacanciesListFilters {
  filter?: MyVacanciesFilterInput
  sort?: MyVacanciesSortType
}

export interface SimpleVacanciesListParams {
  config?: SimpleVacanciesListConfig
  defaultFilters?: SimpleVacanciesListFilters
}

export const SIMPLE_VACANCIES_LIST_PARAMS = new InjectionToken<SimpleVacanciesListParams>('SIMPLE_VACANCIES_LIST_PARAMS')

@Injectable()
export class SimpleVacanciesListService extends RxStateService<{
  response: SimpleVacanciesListResponseFragment | null
  list: SimpleVacanciesListItemFragment[] | null
  defaultFilters: SimpleVacanciesListFilters | null
  filters: SimpleVacanciesListFilters | null
  hasNextPage: boolean
  isLoading: boolean
}> {
  private readonly config = this.params?.config || null
  private readonly defaultFilters = this.params?.defaultFilters || null

  public constructor(
    @Optional() @Inject(SIMPLE_VACANCIES_LIST_PARAMS) private readonly params: SimpleVacanciesListParams | null,
    private readonly vacanciesEmployerIdsService: VacanciesEmployerIdsService,
    private readonly getSimpleVacanciesListGQL: GetSimpleVacanciesListGQL
  ) {
    super()

    this.initState({
      response: null,
      list: this.select('response').pipe(map(response => (response?.items ? getNonNullableItems(response.items) : null))),
      defaultFilters: this.getDefaultFilters$(),
      filters: this.select('defaultFilters'),
      hasNextPage: this.select('response').pipe(map(response => !!response?.pageInfo?.hasNextPage)),
      isLoading: true
    })
  }

  public getList$(): Observable<SimpleVacanciesListItemFragment[] | null> {
    if (!this.get()?.response) {
      this.connect(
        'response',
        this.select('filters').pipe(
          filter(Boolean),
          switchMap(filters =>
            this.fetchVacancies$(filters, { first: this.config?.itemsPerPage }).pipe(
              take(1),
              finalize(() => this.setLoading(false))
            )
          )
        )
      )
    }

    return this.select('list')
  }

  public setFilter(input: MyVacanciesFilterInput): void {
    this.setLoading(true)
    this.set(({ filters }) => ({ filters: { ...filters, filter: { ...filters?.filter, ...input } } }))
  }

  public setSort(value: MyVacanciesSortType): void {
    this.setLoading(true)
    this.set(({ filters }) => ({ filters: { ...filters, sort: value } }))
  }

  public resetFilters(): void {
    this.setLoading(true)
    this.set({ filters: { ...this.get().defaultFilters } })
  }

  public fetchMore(): void {
    const { response, filters, isLoading } = this.get()

    if (!response || !response.pageInfo?.hasNextPage || !filters || isLoading) {
      return
    }

    this.setLoading(true)

    this.hold(this.fetchVacancies$(filters, { after: response.pageInfo?.endCursor, first: this.config?.itemsPerPage }).pipe(finalize(() => this.setLoading(false))), fetchResponse => {
      if (!fetchResponse) {
        return
      }

      this.set({
        response: {
          ...fetchResponse,
          items: [...getNonNullableItems(response.items || []), ...getNonNullableItems(fetchResponse.items || [])]
        }
      })
    })
  }

  public getVacancies$(filters: SimpleVacanciesListFilters): Observable<SimpleVacanciesListResponseFragment | null> {
    const first = filters.filter?.vacancyIds?.length || this.config?.itemsPerPage

    return this.fetchVacancies$(filters, { first })
  }

  public skipWhileLoading$<T>(obs$: Observable<T>): Observable<T> {
    return combineLatest([obs$, this.select('isLoading')]).pipe(
      filter(([_, isLoading]) => !isLoading),
      map(([obs, _]) => obs)
    )
  }

  private getDefaultFilters$(): Observable<SimpleVacanciesListFilters | null> {
    return this.vacanciesEmployerIdsService.getEmployerIds$().pipe(map(employerIds => (employerIds ? { ...this.defaultFilters, filter: { ...this.defaultFilters?.filter, employerIds } } : null)))
  }

  private fetchVacancies$(filters: SimpleVacanciesListFilters, params?: { after?: string | null; first?: number }): Observable<SimpleVacanciesListResponseFragment | null> {
    const { after, first } = params || {}

    return this.getSimpleVacanciesListGQL
      .fetch(
        {
          filter: filters.filter || null,
          sort: filters.sort || null,
          first: first || DEFAULT_ITEMS_PER_PAGE,
          after: after || null
        },
        { fetchPolicy: 'network-only' }
      )
      .pipe(
        map(({ data }) => data?.myVacancies || null),
        catchError(() => of(null))
      )
  }

  private setLoading(value: boolean): void {
    this.set({ isLoading: value })
  }
}
