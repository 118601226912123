import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'

import { RxStateService } from '@alliance/shared/models'

@Injectable({ providedIn: 'root' })
export class CompanyVerificationFeatureService extends RxStateService<{ isEnabled: boolean }> {
  public constructor() {
    super()

    this.initState({ isEnabled: true })
  }

  public isVerificationByCapEnabled$(): Observable<boolean> {
    return this.select('isEnabled')
  }
}
